import { Avatar, Flex, Input, Paper, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconPhoto, IconSearch } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { PageFilter, PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { PopoverText } from "../../components/popover-text/popover-text";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IBusiness } from "../../interfaces/IBusiness";
import { IReference } from "../../models/General";

export const OurBuyerList = () => {
  const breadcrumbs = useBreadcrumb();
  const { businessStatus = [], businessTypes = [], paymentTermConfigTypes = [] } = useSelector((state: any) => state.general);
  const [query, setQuery] = useState<string>("");
  const [debounced] = useDebouncedValue(query, 800);
  const NET_LIST = usePermission("NET_LIST");

  const refTable = useRef<ITableRef>(null);

  const [filters] = useState({
    query: "",
  });

  const columns = useHeader({
    businessStatus,
    businessTypes,
    paymentTermConfigTypes,
    onClick: (key, item) => {},
  });

  useEffect(() => {});

  return (
    <PageLayout title="Манай харилцагчидын жагсаалт" subTitle="Манай харилцагчид" breadcrumb={breadcrumbs}>
      <PageFilter
        left={[
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            key={2}
            placeholder="Бизнесийн нэр"
            w={"220px"}
            leftSection={<IconSearch stroke={1} />}
          />,
        ]}
      />
      <br />
      {NET_LIST.hasAccess ? (
        <Table
          ref={refTable}
          name="our.buyer.network.invitation.receiveds"
          columns={columns}
          filters={{ ...filters, query: debounced }}
          loadData={(data) => NetworkBusinessApi.list(data!)}
        />
      ) : (
        NET_LIST.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  paymentTermConfigTypes,
  businessStatus,
  businessTypes,
  onClick,
}: {
  paymentTermConfigTypes: IReference[];
  businessStatus: IReference[];
  businessTypes: IReference[];
  onClick: (e: string, e1: any) => void;
}): ColumnType<IBusiness>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Flex gap={10} align="center">
        <Paper withBorder>
          <Avatar src={record?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
        </Paper>
        <div>
          <Text size="sm" w="max-content">
            {record?.profileName ?? "-"}
          </Text>
          <Text c="indigo" size="sm">
            #{record?.refCode ?? "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) => (
      <Flex gap={10} align="center">
        <Paper withBorder>
          <Avatar src={record?.partner?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
        </Paper>
        <div>
          <Text size="sm" w="max-content">
            {record?.partner?.businessName ?? "-"}
          </Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.partner?.refCode ?? "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Төлбөр нөхцөл",
    render: (record) =>
      record?.paymentTerm ? (
        <div>
          <Text c="indigo" size="sm">
            {record?.paymentTerm?.refCode ?? "-"}
          </Text>
          <PopoverText>{record?.paymentTerm?.description ?? "-"}</PopoverText>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Орлого авах",
    render: (record) => {
      if (record.inAcc)
        return (
          <Flex gap={10} align="center">
            <Paper withBorder>
              <Avatar src={record?.inAcc.icon} radius="xs">
                <IconPhoto />
              </Avatar>
            </Paper>
            <div>
              <Text size="sm" fw={500}>
                {record?.inAcc.shortName}
              </Text>
              <Text c="dimmed" size="sm" w="max-content">
                {record?.inAcc.name}
                <Text component="span" fw={700} size="sm">
                  , {record?.inAcc.number}
                </Text>
              </Text>
            </div>
          </Flex>
        );
      return "-";
    },
  },
  {
    title: "Зарлага гаргах",
    render: (record) => {
      if (record.outAcc) {
        return (
          <Flex align="center" gap={10}>
            <Paper withBorder>
              <Avatar src={record?.inAcc.icon}>
                <IconPhoto />
              </Avatar>
            </Paper>
            <div>
              <Text size="sm" fw={500}>
                {record?.outAcc.shortName}
              </Text>
              <Text c="dimmed" size="sm" w="max-content">
                {record?.outAcc.name}
                <Text component="span" fw={700} size="sm">
                  , {record?.outAcc.number}
                </Text>
              </Text>
            </div>
          </Flex>
        );
      }
      return "-";
    },
  },
  {
    title: "Ангилал",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text w="max-content" size="sm">
            {record?.classificationCategory?.name}
          </Text>
          <Text c="grape" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.classificationPriority?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Статус",
    render: (record) =>
      businessStatus?.find((f) => f.code === record.businessStatus)
        ? Tag(
            businessStatus?.find((f) => f.code === record.businessStatus)?.color ?? "gray",
            businessStatus?.find((f) => f.code === record.businessStatus)?.name ?? "-",
          )
        : "-",
  },
  {
    title: "Санхүү хариуцсан",
    render: (record) =>
      record?.coBusinessStaff ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.coBusinessStaff?.avatar} />
          <div>
            <Text size="sm" fw={500} tt={"capitalize"} w="max-content">
              {record?.coBusinessStaff?.lastName ? record?.coBusinessStaff?.lastName[0] + "." : ""}
              {record?.coBusinessStaff?.firstName ?? "-"}
            </Text>
            <Text c="gray" size="sm">
              {record?.coBusinessStaff?.email ?? ""}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "И-мэйл хаяг",
    render: (record) => <Text size="sm">{record?.partnerEmail ?? "-"}</Text>,
  },
  {
    title: "Утасны дугаар",
    render: (record) => <Text size="sm">{record?.partnerPhone ?? "-"}</Text>,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Манай харилцагчид",
  },
];
