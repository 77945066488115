import { ActionIcon, Avatar, Badge, Box, Flex, SegmentedControl, Space, Text, Tooltip } from "@mantine/core";
import { IconFileText, IconMailFast, IconMailForward, IconPhoto } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { NetworkInvitationApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IInvitationSenderItem } from "../../interfaces/IInvitationSenderItem";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "../tags";

export function InvitedBuyerList({
  setAction,
  refTable,
  filters,
  setFilters,
}: {
  setAction: (e: any) => void;
  refTable: any;
  setFilters: (e: any) => void;
  filters: any;
}) {
  const { invitationStatus = [], invitationTypes = [] } = useSelector((state: any) => state.general);

  const columns = useHeader({
    invitationStatus,
    invitationTypes,
    onClick: async (key, record) => {
      let res = await NetworkInvitationApi.get(record.id || "");
      setAction([key, res]);
    },
  });

  return (
    <div>
      <SegmentedControl
        key={1}
        onChange={(e) => setFilters({ ...filters, status: e })}
        value={filters.status}
        data={[{ name: "Бүгд", code: "" }, ...(invitationStatus ?? [])].map((item: IReference, index: number) => {
          return {
            value: item.code,
            label: (
              <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
                {index !== 0 && <Box style={{ width: 8, height: 8, borderRadius: "100%", background: `${item?.color}` }} />}
                {item?.name ?? "-"}
              </Box>
            ),
          };
        })}
      />
      <Space h="md" />
      <Table ref={refTable} name="network.invitation.sent.buyer" columns={columns} filters={filters} loadData={(data) => NetworkInvitationApi.sent(data!)} />
    </div>
  );
}

const useHeader = ({
  onClick,
  invitationStatus,
  invitationTypes,
}: {
  onClick: (key: string, record: IInvitationSenderItem) => void;
  invitationStatus: IReference[];
  invitationTypes: IReference[];
}): ColumnType<IInvitationSenderItem>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    align: "center",
    render: (record) => {
      return (
        <Flex align="center" gap={10}>
          <Tooltip label="Дэлгэрэнгүй">
            <ActionIcon onClick={() => onClick("details", record)} color={"indigo"} variant="light">
              <IconFileText size={18} />
            </ActionIcon>
          </Tooltip>
          {record.invitationStatus === "DRAFT" && (
            <Tooltip label="Урих">
              <ActionIcon onClick={() => onClick("draft_invite", record)} color={"indigo"} variant="light" disabled={record?.invitationStatus !== "DRAFT"}>
                {record?.invitationStatus === "DRAFT" ? <IconMailForward size={18} stroke={2} /> : <IconMailFast size={18} stroke={2} />}
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Урилга",
    dataIndex: "refCode",
    render: (record) => (
      <Text w={"100px"} c="primary" size="sm">
        {record?.refCode}
      </Text>
    ),
  },
  {
    title: "Урилга статус",
    dataIndex: "invitationStatus",
    render: (record) =>
      Tag(
        invitationStatus.find((item: any) => item.code === record.invitationStatus)?.color || "gray",
        invitationStatus.find((item: any) => item.code === record.invitationStatus)?.name || "-",
      ),
  },
  {
    title: "Огноо, цаг",
    dataIndex: "invitedDate",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.invitedDate ? dateTimeFormat(record?.invitedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Партнер нэр",
    dataIndex: "receiver.partnerName",
    render: (record) =>
      record?.receiver?.partner ? (
        <div>
          <Text w={"max-content"} size="sm">
            {record?.receiver?.partner?.businessName || "-"}
          </Text>
          <Text c="indigo" size="sm">
            # {record?.receiver?.partner?.refCode || "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Buyer админ",
    dataIndex: "receiver.staff.lastName",
    render: (record) =>
      record?.receiver?.staff?.firstName ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.receiver?.staff?.avatar} />
          <Text tt={"capitalize"} size="sm">
            {record?.receiver?.staff?.lastName ? record?.receiver?.staff?.lastName[0] + "." : ""}
            {record?.receiver?.staff?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "И-мэйл хаяг",
    dataIndex: "receiver.staff.email",
    render: (record) => <Text size="sm">{record?.receiver?.staff?.email || "-"}</Text>,
  },
  {
    title: "Утасны дугаар",
    dataIndex: "receiver.staff.phone",
    render: (record) => <Text size="sm">{record?.receiver?.staff?.phone || "-"}</Text>,
  },
  {
    title: "Buyer роль",
    dataIndex: "receiver.typeBuyer",
    render: (record) => (
      <Badge variant="light" color={record?.receiver?.type === "BUYER" ? "primary" : "gray"}>
        {record?.receiver?.type === "BUYER" ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Supplier роль",
    dataIndex: "receiver.typeSupplier",
    render: (record) => (
      <Badge variant="light" color={record?.receiver?.type === "SUPPLIER" ? "primary" : "gray"}>
        {record?.receiver?.type === "SUPPLIER" ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Урилга хүлээн авсан бизнес",
    dataIndex: "receiver.profileName",
    render: (record) =>
      record?.receiver?.refCode ? (
        <Flex w={"max-content"} gap={10}>
          <Avatar src={record?.receiver?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm">{record?.receiver?.profileName || "-"}</Text>
            <Text c="indigo" size="sm">
              #{record?.receiver?.refCode || "-"}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (record) => (
      <Text w="max-content" size="sm">
        {invitationTypes?.find((item: any) => item.code === record.type)?.name || record.type || "-"}
      </Text>
    ),
  },
];
