import { Avatar, Badge, Checkbox, Flex, Paper, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";

export function DistributionAreaList({
  action,
  setAction,
  selected,
  setSelected,
  refTable,
}: {
  action: any;
  setAction: any;
  selected: string[];
  setSelected: (e: string[]) => void;
  reload: () => void;
  refTable: any;
}) {
  const { businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);

  const columns = useHeader({
    selected,
    businessStatus,
    onClick: (key: string, item: any) => {
      if (key === "select_all") {
        setSelected(item);
      }
      if (key === "unselect_all") {
        setSelected([]);
      }
      if (key === "select") {
        if (selected.some((item1: any) => item1.id === item.id)) {
          setSelected([...selected.filter((item1: any) => item1.id !== item.id)]);
        } else setSelected([...selected, item]);
      } else setAction([key, { ...item, businessIds: selected }]);
    },
  });

  const columnsBuyerView = useHeaderBuyerView({
    selected,
    businessStatus,
    onClick: (key: string, item: any) => {
      if (key === "select_all") {
        setSelected(item);
      }
      if (key === "unselect_all") {
        setSelected([]);
      }
      if (key === "select") {
        if (selected.some((item1: any) => item1.id === item.id)) {
          setSelected([...selected.filter((item1: any) => item1.id !== item.id)]);
        } else setSelected([...selected, item]);
      } else setAction([key, { ...item, businessIds: selected }]);
    },
  });

  const [filters] = React.useState({ query: "" });

  return (
    <Table
      ref={refTable}
      name="NetworkBusinessApi.list"
      columns={businessType === "BUYER" ? columnsBuyerView : columns}
      filters={filters}
      loadData={(data) => NetworkBusinessApi.list(data!)}
    />
  );
}

const useHeader = ({
  onClick,
  selected,
  businessStatus,
}: {
  onClick: (e: string, e1: any) => void;
  selected: any[];
  businessStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    renderTitle(record) {
      return (
        <Checkbox
          onChange={(e) => {
            if (!e.target.checked) {
              onClick("unselect_all", []);
            } else onClick("select_all", record?.rows);
          }}
          onClick={() => {}}
          checked={selected?.length === record?.rows?.length}
        />
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={selected.some((item: any) => item.id === record.id)}
        />
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.regNumber ?? "-"}
      </Text>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.partner?.businessName ?? "-"}
          </Text>
          <Text c="indigo" size="sm">
            #{record?.partner?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <div>
        <Text w={"max-content"} size="sm">
          {record?.profileName ?? "-"}
        </Text>
        <Text c="indigo" size="sm" w="max-content">
          #{record?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (!!record?.branch?.name)
        return (
          <div>
            <Text size="sm" w="max-content">
              {record?.branch?.name ?? "-"}
            </Text>
            <Text c="indigo" size="sm">
              #{record?.branch?.refCode ?? "-"}
            </Text>
          </div>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.classificationCategory?.name ?? "-"}
          </Text>
          <Text w="max-content" c="indigo" size="sm">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Статус",
    dataIndex: "businessStatus",
    width: "200px",
    render: (record) => (
      <Badge
        color={businessStatus.find((item: IReference) => item.code === record.businessStatus)?.color ?? "indigo"}
        styles={{ label: { overflow: "visible", textOverflow: "unset" } }}
        variant="light">
        {businessStatus.find((item: IReference) => item.code === record.businessStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "areaRegion",
    width: "200px",
    render: (record) =>
      record?.areaRegion ? (
        <div>
          <Text size="sm">{record?.areaRegion?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.areaRegion?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Чиглэлийн нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <div>
        <Text size="sm">{record?.areaDirection?.name ?? "-"}</Text>
        {record?.areaDirection?.refCode && (
          <Text c="indigo" size="sm">
            #{record?.areaDirection?.refCode ?? "-"}
          </Text>
        )}
      </div>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.areaDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Хариуцсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaff?.firstName ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.businessStaff?.avatar} />
          <Text size="sm" w="max-content">
            {record?.businessStaff?.lastName ? record?.businessStaff?.lastName[0] + "." : ""}
            {record?.businessStaff?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Орлох ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.businessStaff2 && record?.businessStaff2?.firstName)
        return (
          <Flex gap={10} align="center">
            <Avatar src={record?.businessStaff2?.avatar} />
            <Text size="sm" w="max-content">
              {record?.businessStaff2?.lastName ? record?.businessStaff2?.lastName[0] + "." : ""} {record?.businessStaff2?.firstName}
            </Text>
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал тохируулсан огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.classificationSetDate ? dateTimeFormat(record.classificationSetDate) : "-"}</Text>,
  },
  {
    title: "Бүс тохируулсан огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.areaSetDate ? dateTimeFormat(record.areaSetDate) : "-"}</Text>,
  },
  {
    title: "Хуваарилсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.areaSetUser?.firstName ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.areaSetUser?.avatar} />
          <Text tt={"capitalize"} size="sm" w="max-content">
            {record?.areaSetUser?.lastName && record?.areaSetUser?.lastName[0] + "."}
            {record?.areaSetUser?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
];

const useHeaderBuyerView = ({
  onClick,
  selected,
  businessStatus,
}: {
  onClick: (e: string, e1: any) => void;
  selected: any[];
  businessStatus: IReference[];
}): ColumnType<any>[] => [
  {
    renderTitle(record, index) {
      return (
        <>
          <Checkbox
            onChange={(e) => {
              if (!e.target.checked) {
                onClick("unselect_all", []);
              } else onClick("select_all", record?.rows);
            }}
            onClick={() => {}}
            checked={selected?.length > 0}
          />
        </>
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={selected.some((item: any) => item.id === record.id)}
        />
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.regNumber ?? "-"}</Text>,
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <div>
          <Text size="sm">{record?.partner?.businessName ?? "-"}</Text>
          <Text c="indigo" size="sm">
            #{record?.partner?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <div>
        <Text w={"max-content"} size="sm">
          {record?.profileName ?? "-"}
        </Text>
        <Text c="indigo" size="sm">
          #{record?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (!!record?.branch?.name)
        return (
          <div>
            <Text size="sm">{record?.branch?.name ?? "-"}</Text>
            <Text c="indigo" size="sm">
              #{record?.branch?.refCode ?? "-"}
            </Text>
          </div>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text size="sm">{record?.classificationCategory?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Статус",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <Badge
        color={businessStatus.find((item: IReference) => item.code === record.businessStatus)?.color ?? "indigo"}
        variant="light"
        styles={{ label: { overflow: "visible", textOverflow: "unset" } }}>
        {businessStatus.find((item: IReference) => item.code === record.businessStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.areaDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Хариуцсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      console.log("dadada:::::::::>", record?.businessStaff);
      return record?.businessStaff ? (
        <Flex gap={10} align="center" w={"max-content"}>
          <Avatar src={record?.businessStaff?.avatar} />
          <Text size="sm">
            {record?.businessStaff?.lastName ? record?.businessStaff?.lastName[0] + "." : ""}
            {record?.businessStaff?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Орлох ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.businessStaff2 && record?.businessStaff2?.firstName)
        return (
          <Flex gap={10} align="center" w={"max-content"}>
            <Paper withBorder>
              <Avatar src={record?.businessStaff2?.avatar} />
            </Paper>
            <Text size="sm">
              {record?.businessStaff2?.lastName ? record?.businessStaff2?.lastName[0] + "." : ""}
              {record?.businessStaff2?.firstName}
            </Text>
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Тохиргоо хийсэн",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaffSetUser?.firstName ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.businessStaffSetUser?.avatar} />
          <Text tt={"capitalize"} size="sm" w="max-content">
            {record?.businessStaffSetUser?.lastName && record?.businessStaffSetUser?.lastName[0] + "."} {record?.businessStaffSetUser?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
];
