import { Alert, Anchor, Badge, Button, Divider, Flex, Grid, Group, Paper, Select, Skeleton, Space, Tabs, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import {
  IconArrowDown,
  IconArrowUp,
  IconBuilding,
  IconCalendar,
  IconCaretRight,
  IconExclamationCircle,
  IconMail,
  IconSend,
  IconUserPlus,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import { DashboardApi } from "../../apis";
import { useScreenDetector } from "../../components/screen-detector";
import { IGeneral } from "../../interfaces/IGeneral";
import { LastInvitationInvitedList } from "./last-invitation-invited-list";
import { LastInvitationSentList } from "./last-invitation-sent.list";

export function Dashboard() {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const [filter, setFilter] = useState({
    categoryId: null,
    condition: null,
    date: dayjs().format("YYYY-MM-DD"),
  });
  const { clientClassifications, paymentTermConditions } = useSelector((state: { general: IGeneral }) => state.general);
  const [categoryId, setCategoryId] = useState<string>("");
  const [condition, setCondition] = useState<string>("");
  const { data = null } = useSwr<any>(
    `/biz/dashboard/main?${JSON.stringify({ ...filter, categoryId: categoryId, condition: condition })}`,
    async () => await DashboardApi.main({ ...filter, categoryId: categoryId, condition: condition }),
    {
      fallback: [],
    },
  );

  const getOptions = ({ data }: { data: any }) => ({
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: data?.networkCount?.map((item: any, index: number) => {
          return item?.date;
        }),
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      verticalAlign: "top",
      y: -10,
    },
    series: [
      {
        name: "Нийт харилцагч",
        color: "#82138B",
        type: "column",
        data: data?.networkCount?.map((item: any, index: number) => {
          return item?.previousCount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Шинэ харилцагч",
        type: "spline",
        color: "#F48B25",
        data: data?.networkCount?.map((item: any, index: number) => {
          return item?.newCount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
  });

  const dummiData = {
    networkCount: [
      {
        previousCount: 1,
        newCount: 1,
        date: "III.1",
      },
      {
        previousCount: 2,
        newCount: 2,
        date: "III.2",
      },
      {
        previousCount: 5,
        newCount: 5,
        date: "III.3",
      },
      {
        previousCount: 8,
        newCount: 8,
        date: "III.4",
      },
      {
        previousCount: 10,
        newCount: 6,
        date: "III.5",
      },
      {
        previousCount: 13,
        newCount: 3,
        date: "III.6",
      },
      {
        previousCount: 20,
        newCount: 5,
        date: "III.7",
      },
      {
        previousCount: 28,
        newCount: 8,
        date: "III.8",
      },
      {
        previousCount: 30,
        newCount: 4,
        date: "III.9",
      },
      {
        previousCount: 31,
        newCount: 3,
        date: "III.10",
      },
    ],
  };

  function category() {
    return (
      <div>
        <Paper p={"sm"} withBorder>
          <Text fz={"lg"} fw={600}>
            Ангилал, зэрэглэлээр
          </Text>
          <Space h={"xs"} />
          <Text>Нийт тоо</Text>
          <Group align="apart">
            <Text fw={600} fz="xl">
              {data?.byClassification?.count || 0}
            </Text>
            {data?.byClassification?.changedCount > 0 ? (
              <Badge color="green">+{data?.byClassification?.changedCount || 0}</Badge>
            ) : (
              <Badge color="indigo">{data?.byClassification?.changedCount || 0}</Badge>
            )}
          </Group>
          <Divider my={"lg"} />
          <Select
            searchable
            value={categoryId}
            onChange={(e: string | null) => {
              if (e) setCategoryId(e);
            }}
            placeholder="Ангилал сонгох"
            data={clientClassifications
              .filter((item: any) => !item.parentId)
              .map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                  description: item.description,
                };
              })}
          />
          <Space h={"sm"} />
          {categoryId ? (
            <Flex direction={"column"} gap={"sm"}>
              {data?.byClassification?.priorities?.map((item: any, index: number) => {
                return (
                  <Group align="apart" key={index}>
                    <Text>{item?.name}</Text>
                    <Flex align={"center"} gap={8}>
                      <Text fz={"sm"} fw={600}>
                        {item.count}
                      </Text>
                      <Badge color="green">+{item.changedCount}</Badge>
                    </Flex>
                  </Group>
                );
              })}
              <Button radius={"md"}>Бүгдийг үзэх</Button>
            </Flex>
          ) : (
            <Alert icon={<IconExclamationCircle />} color="red">
              Ангилал сонгоно уу.
            </Alert>
          )}
        </Paper>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Group align="apart" mb={"xs"}>
        <Text size={"xl"} fw={600}>
          Тоон тойм
        </Text>
        <DatePickerInput
          excludeDate={(date) => {
            return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
          }}
          value={new Date(filter.date)}
          onChange={(e: any) => {
            if (e) {
              setFilter({
                ...filter,
                date: e,
              });
            } else {
              setFilter({
                ...filter,
                date: dayjs().format("YYYY-MM-DD"),
              });
            }
          }}
          style={{ borderRadius: "5px" }}
          variant="unstyled"
          bg={"#F7F6FD"}
          maw={"300px"}
          w={"100%"}
          placeholder="Огноо хайх"
          leftSection={<IconCalendar size={"1.2rem"} />}
          valueFormat="YYYY-MM-DD"
        />
      </Group>

      <Grid>
        {data?.numberSurvey ? (
          data?.numberSurvey?.map((stat: any, index: number) => {
            const DiffIcon = stat.changedCount > 0 ? IconArrowUp : IconArrowDown;

            return (
              <Grid.Col span={{ xs: 12, sm: 6 }} key={index}>
                <Paper withBorder key={stat.key} radius={"md"}>
                  <Group p={"sm"} pb={"xs"}>
                    {index === 0 && <IconBuilding size={"1.2rem"} color={theme.colors.gray[6]} />}
                    {index === 1 && <IconUserPlus size={"1.2rem"} color={theme.colors.gray[6]} />}
                    {index === 2 && <IconMail size={"1.2rem"} color={theme.colors.gray[6]} />}
                    {index === 3 && <IconSend size={"1.2rem"} color={theme.colors.gray[6]} />}
                    <Text fz={"sm"} fw={600} c={theme.colors.gray[6]}>
                      {stat.name}
                    </Text>
                  </Group>
                  <Group>
                    <div className={classes.grdnt}></div>
                    <Space />
                    <Text fz={"xl"} fw={700}>
                      {stat.count}
                    </Text>
                  </Group>
                  <Group align="right" p={"sm"}>
                    <Text size={"xs"} c={theme.colors.gray[6]}>
                      өмнөх сараас
                    </Text>
                    <Badge color={stat.changedCount > 0 ? "green" : "red"} className={stat.changedCount > 0 ? classes.green : classes.red}>
                      <Flex align={"center"} gap={8}>
                        <DiffIcon size={"0.8rem"} stroke={3} />
                        <Text>{Math.abs(stat.changedCount)} %</Text>
                      </Flex>
                    </Badge>
                  </Group>
                </Paper>
              </Grid.Col>
            );
          })
        ) : (
          <>
            {[1, 2, 3, 4].map((index: number) => {
              return (
                <Grid.Col span={{ xs: 12, sm: 6 }} key={index}>
                  <Skeleton h={"121px"} w={"100%"} />
                </Grid.Col>
              );
            })}
          </>
        )}
        <Grid.Col>
          <Paper withBorder py={"xs"}>
            <Group>
              <div className={classes.grdnt}></div>
              <Text component="h1">Харилцагчийн тоо</Text>
            </Group>
            <Paper px={"lg"}>
              <Tabs defaultValue={"1"}>
                <Tabs.List>
                  <Tabs.Tab value="1">Жишээ</Tabs.Tab>
                  <Tabs.Tab value="2">Бодит</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="1">
                  <HighchartsReact highcharts={Highcharts} options={getOptions({ data: dummiData })} />
                </Tabs.Panel>
                <Tabs.Panel value="2">
                  <HighchartsReact highcharts={Highcharts} options={getOptions({ data: data })} />
                </Tabs.Panel>
              </Tabs>
            </Paper>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 9 }}>
          <Paper withBorder py={"4px"}>
            <Flex align="center" gap="md">
              <Flex align="center" gap="sm">
                <div className={classes.grdnt}></div>
                <Text component="h1">Сүүлд ирсэн урилга</Text>
              </Flex>
              <Anchor onClick={() => navigate("/came")} size={"xs"} mr={"lg"}>
                {isMobile ? <IconCaretRight /> : "Бүгдийг харах"}
              </Anchor>
            </Flex>
            <Paper px={"lg"}>
              <LastInvitationInvitedList receivedInvitations={data?.receivedInvitations || []} />
            </Paper>
          </Paper>
          <Space h={"md"} />

          <Paper withBorder py={"4px"}>
            <Flex align="center" gap="md">
              <Flex align="center" gap="sm">
                <div className={classes.grdnt}></div>
                <Text component="h1">Сүүлд илгээсэн урилга</Text>
              </Flex>
              <Anchor onClick={() => navigate("/sent")} size={"xs"} mr={"lg"}>
                {isMobile ? <IconCaretRight /> : "Бүгдийг харах"}
              </Anchor>
            </Flex>
            <Paper px={"lg"}>
              <LastInvitationSentList sentInvitations={data?.sentInvitations || []} />
            </Paper>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }}>
          {category()}

          <Space h={"md"} />

          <Paper p={"sm"} withBorder>
            <Text fz={"lg"} fw={600}>
              Төлбөрийн хэлбэрээр
            </Text>
            <Space h={"xs"} />
            <Text>Нийт тоо</Text>
            <Group align="apart">
              <Text fw={600} fz="xl">
                {data?.byPaymentTermCondition?.count || 0}
              </Text>
              {data?.byPaymentTermCondition?.changedCount > 0 ? (
                <Badge color="green">+{data?.byPaymentTermCondition?.changedCount}%</Badge>
              ) : (
                <Badge color="red">{data?.byPaymentTermCondition?.changedCount}%</Badge>
              )}
            </Group>
            <Divider my={"lg"} />
            <Select
              onChange={(e: string | null) => {
                if (e) setCondition(e);
              }}
              value={condition}
              placeholder="Нөхцөл сонгох"
              data={paymentTermConditions.map((item: any) => {
                return {
                  label: item.name,
                  value: item.code,
                  description: item.description,
                };
              })}
            />
            <Space h={"md"} />
            {condition ? (
              <Flex direction={"column"} gap={"sm"}>
                {data?.byPaymentTermCondition?.paymentTerms?.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <Divider mb={"xs"} />
                      <Group align="apart">
                        <Text fz={"sm"} w={"200px"}>
                          {item?.description}
                        </Text>
                        <Flex align={"center"} gap={8}>
                          <Text fw={600}>{item.count}</Text>
                          {item?.advancePercent > 0 ? (
                            <Badge color="indigo">+{item?.advancePercent}%</Badge>
                          ) : (
                            <Badge color="indigo">{item?.advancePercent || 0}%</Badge>
                          )}
                        </Flex>
                      </Group>
                    </div>
                  );
                })}
                <Button radius={"md"}>Бүгдийг үзэх</Button>
              </Flex>
            ) : (
              <Alert icon={<IconExclamationCircle />} color="red">
                Төлбөрийн нөхцөл сонгоно уу.
              </Alert>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: theme.colors.primary[3],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
