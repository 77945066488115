import { Space, Tabs, Text } from "@mantine/core";
import { IconBuilding } from "@tabler/icons-react";
import { useState } from "react";
import { InvitionPartner } from "../../components/invition-came/partner-list";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";

export const InvitionCameList = () => {
  const breadcrumbs = useBreadcrumb();
  const NET_INV_REC = usePermission("NET_INV_REC");

  const [tabKey, setTabKey] = useState<string>("NETWORK");

  return (
    <PageLayout title="Манайд ирсэн урилгын жагсаалт" subTitle="Манайд ирсэн урилга" breadcrumb={breadcrumbs}>
      {NET_INV_REC.hasAccess ? (
        <Tabs
          value={tabKey}
          onChange={(e: string | null) => {
            if (e) setTabKey(e);
          }}
          defaultValue={tabKey}>
          <Tabs.List>
            <Tabs.Tab value="NETWORK" leftSection={<IconBuilding size={18} />}>
              <Text>Партнераас ирсэн</Text>
            </Tabs.Tab>
          </Tabs.List>
          <Space h="md" />
          <Tabs.Panel value="NETWORK">
            <InvitionPartner />
          </Tabs.Panel>
        </Tabs>
      ) : (
        NET_INV_REC.accessDenied()
      )}
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Манайд ирсэн урилга",
  },
];
