import { Button, CloseButton, Divider, Grid, Group, Input, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkInvitationApi } from "../../apis";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  toMessage: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна.").nullable(),
});

type IFormData = {
  partnerName: string | null;
  toMessage: string | null;
  send: boolean;
  receiverIds: any[];
};

export function InviteDraftForm({
  action,
  setAction,
  title,
  subTitle,
  refTable,
}: {
  action: any[];
  setAction: (e: any) => void;
  title: string;
  subTitle: string;
  refTable: any;
}) {
  const { user } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useState<IFormData | any>({
    toMessage: null,
    send: false,
    ...action[1],
  });

  const onSubmit = async (values: any, send: boolean) => {
    setLoading(true);

    if (send) {
      setAction(["pin", values]);
    } else {
      try {
        await NetworkInvitationApi.update(action[1].id, {
          toMessage: values.toMessage,
          send: false,
        });

        message.success(`Урилга амжилттай хадгалагдлаа. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);

        await refTable.current.reload();

        setAction([]);
      } catch (error: any) {
        message.error(error.message ?? "Хүсэлт амжилтгүй.");
      }
    }
    setLoading(false);
  };

  let now = new Date();

  return (
    <Form validationSchema={schema} onSubmit={(e) => e} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setAction && setAction([])} />]}>
            <Paper>
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилгын №">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.refCode}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилга илгээж буй ажилтан">
                    <Text c="indigo" fw={500} size="sm">
                      {user?.lastName ?? "-"} {user.firstName ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилгын огноо, цаг">
                    <Text c="indigo" fw={500} size="sm">
                      {dayjs(now).format("YYYY-MM-DD HH:mm")}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилга илгээж буй партнер">
                    <Text c="indigo" fw={500} size="sm">
                      {user?.partner?.businessName}, # {user?.partner?.refCode}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилга илгээж буй бизнес">
                    <Text c="indigo" fw={500} size="sm">
                      {user?.currentBusiness?.profileName ?? "-"}, #{user?.currentBusiness?.refCode ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Санхүү хариуцсан ажилтан">
                    <Text c="indigo" fw={500} size="sm">
                      {user?.currentBusiness?.staff?.lastName ?? "-"} {user?.currentBusiness?.staff?.firstName ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField
                    name="toMessage"
                    label="Урилгад очих зурвас:"
                    placeholder="Урилгад очих зурвас"
                    required
                    disabled={action[0] && action[0] === "details"}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <Divider my="sm" p={0} />
                  <Text component="span" size={"lg"} fw={500}>
                    Урилга хүлээн авах бизнес
                  </Text>
                  <Text size={"sm"} c="gray">
                    Урилга хүлээн авах бизнесын мэдээлэл
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Партнер нэр, код">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.receiver?.partnerName ?? "-"}, #{data?.receiver?.refCode ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Бизнес нэр, код">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.receiver?.profileName ?? "-"}, #{data?.receiver?.refCode ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="ТТД">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.receiver?.regNumber ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Хариуцсан ажилтан">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.receiver?.staff.lastName} {data?.receiver?.staff.firstName ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="И-мэйл хаяг">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.receiver?.staff.email ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Утасны дугаар">
                    <Text c="indigo" fw={500} size="sm">
                      {data?.receiver?.staff.phone ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                {action[0] !== "details" && (
                  <Grid.Col>
                    <Divider my="lg" />
                    <Group justify="right">
                      <Button
                        loading={loading}
                        onClick={() => {
                          if (values.toMessage) onSubmit(values, false);
                        }}
                        type="submit"
                        variant="outline">
                        Хадгалах
                      </Button>
                      <Button
                        loading={loading}
                        type="submit"
                        onClick={() => {
                          if (values.toMessage) onSubmit(values, true);
                        }}>
                        Илгээх
                      </Button>
                    </Group>
                  </Grid.Col>
                )}
              </Grid>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}
