import { Box, Button, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ClientClassificationsApi, GeneralApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/Message";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

type Props = {
  onCancel: () => void;
  title: string;
  subTitle: any;
  action: any[];
  payload?: any;
  reload?: any;
};

type IFormData = {
  name: string;
  description: string;
  parentId: string;
};

const schema = yup.object({
  name: yup.string().max(255, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэтэрхий урт байна!").trim().nullable(),
  parentId: yup.string().required("Заавал бөглөнө!").nullable(),
});

export function ClientPriorityForm({ title, subTitle, onCancel, action, reload }: Props) {
  const nowDate = new Date();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { clientClassifications } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [data] = React.useState<IFormData>({
    name: "",
    description: "",
    parentId: "",
    ...(action && action[0] === "update" ? action[1] : action && action[0] === "detail" ? action[1] : {}),
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      if (action[0] === "update") {
        await ClientClassificationsApi.update(action && action[1]?.id, {
          name: data.name,
          description: data.description,
        });
        message.success("Амжилттай нийлүүлэгчийн зэрэглэлийг засварласан!");
      } else {
        await ClientClassificationsApi.create({
          name: data.name,
          description: data.description,
          parentId: data.parentId,
        });
        message.success("Амжилттай шинэ нийлүүлэгчийн зэрэглэл нэмлээ!");
      }
      const res = await GeneralApi.init();
      dispatch(initGeneral(res));
      setLoading(false);
      reload();
      onCancel && onCancel();
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <Divider mb="sm" />
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бизнес Нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text c="#DA77F2" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[0] === "new" && user?.currentBusiness?.refCode} {action[0] === "new" && user?.currentBusiness?.profileName}
                      {action[0] === "update" && action[1]?.business?.refCode} {action[0] === "update" && action[1]?.business?.profileName}
                      {action[0] === "detail" && action[1]?.business?.refCode} {action[0] === "detail" && action[1]?.business?.profileName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text c="#DA77F2" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[0] === "new" && user?.lastName} {action[0] === "new" && user?.firstName}
                      {action[0] === "update" && action[1]?.regUser?.lastName} {action[0] === "update" && action[1]?.regUser?.firstName}
                      {action[0] === "detail" && action[1]?.regUser?.lastName} {action[0] === "detail" && action[1]?.regUser?.firstName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн огноо, цаг:">
                  <Box style={{ alignItems: "center" }}>
                    <Text c="#DA77F2" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[0] === "new" && dateSecFormat(nowDate)}
                      {action[0] === "update" && dateSecFormat(action && action[1].createdAt)}
                      {action[0] === "detail" && dateSecFormat(action && action[1].createdAt)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="parentId"
                  label="Ангилал"
                  placeholder="Сонгох"
                  clearable
                  required
                  disabled={action && action[0] !== "new"}
                  options={clientClassifications.filter((c) => (c?.isParent as any) === true).map((val) => ({ label: val.name, value: val.id }))}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="name" label="Нэр" placeholder="Нэр" disabled={action && action[0] === "detail"} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextareaField name="description" label="Тайлбар" placeholder="Талбар...." disabled={action && action[0] === "detail"} />
              </Grid.Col>
            </Grid>

            <Divider mt="md" />
            <Flex mt="md" justify="flex-end" gap={10}>
              <Button variant="outline" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              {action && action[0] !== "detail" && (
                <Button loading={loading} variant="filled" type="submit">
                  Хадгалах
                </Button>
              )}
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
