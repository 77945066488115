import { Avatar, Box, Flex, Image, Input, Modal, Paper, Text, UnstyledButton } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconPhoto, IconSearch } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { PageFilter, PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IBusiness } from "../../interfaces/IBusiness";
import { IReference } from "../../models/General";

export const OurSupplierList = () => {
  const breadcrumbs = useBreadcrumb();
  const { businessStatus = [], paymentTermConfigTypes = [] } = useSelector((state: any) => state.general);
  const refTable = useRef<ITableRef>(null);
  const NET_LIST = usePermission("NET_LIST");
  const [action, setAction] = useState<any[]>([]);
  const [value, setValue] = useDebouncedState("", 800);

  const columns = useHeader({
    businessStatus,
    businessTypes,
    paymentTermConfigTypes,
    onClick: (key, item) => {
      setAction([key, item]);
    },
  });

  return (
    <PageLayout title="Манай нийлүүлэгчдын жагсаалт" subTitle="Манай нийлүүлэгчид" breadcrumb={breadcrumbs}>
      <PageFilter
        left={[<Input onChange={(e) => setValue(e.target.value)} placeholder="Бизнесийн нэр" w={"220px"} leftSection={<IconSearch stroke={1} />} key={3} />]}
      />
      <br />
      {NET_LIST.hasAccess ? (
        <Table
          ref={refTable}
          name="our.supplier.network.invitation.receiveds"
          columns={columns}
          filters={{ query: value }}
          loadData={(data) => NetworkBusinessApi.list(data!)}
        />
      ) : (
        NET_LIST.accessDenied()
      )}
      {NET_LIST.hasAccess && (
        <Modal centered opened={action[0] === "image"} onClose={() => setAction([])} padding={0} title={false} withCloseButton={false}>
          <Image src={action[1] && action[1]} />
        </Modal>
      )}
    </PageLayout>
  );
};

const useHeader = ({
  paymentTermConfigTypes,
  businessStatus,
  businessTypes,
  onClick,
}: {
  paymentTermConfigTypes: IReference[];
  businessStatus: IReference[];
  businessTypes: IReference[];
  onClick: (e: any, e1: any) => void;
}): ColumnType<IBusiness>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Flex gap={10} w="max-content" align={"center"}>
        <UnstyledButton disabled={!record?.logo} onClick={() => onClick("image", record?.logo)}>
          <Paper withBorder>
            <Avatar radius="xs" src={record?.logo}>
              <IconPhoto />
            </Avatar>
          </Paper>
        </UnstyledButton>
        <div>
          <Text w={"max-content"} size="sm">
            {record?.profileName ?? "-"}
          </Text>
          <Text c="indigo" size="sm">
            #{record?.refCode ?? "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) => (
      <Flex gap={10} w={"max-content"} align="center">
        <UnstyledButton disabled={!record?.partner?.logo} onClick={() => onClick("image", record?.partner?.logo)}>
          <Paper withBorder>
            <Avatar radius="xs" src={record?.partner?.logo}>
              <IconPhoto />
            </Avatar>
          </Paper>
        </UnstyledButton>
        <div>
          <Text size="sm">{record?.partner?.businessName ?? "-"}</Text>
          <Text c="indigo" size="sm">
            #{record?.partner?.refCode ?? "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Төлбөр нөхцөл",
    render: (record) =>
      record?.paymentTerm ? (
        <Text w="max-content">
          <Text c="indigo" size="sm">
            {record?.paymentTerm?.refCode ?? "-"}
          </Text>
          <Text size="sm">{record?.paymentTerm?.description ?? "-"}</Text>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Орлого авах",
    render: (record) => {
      if (record.inAcc)
        return (
          <Flex align="center" gap={10}>
            <Paper withBorder>
              <Avatar radius="xs" src={record?.inAcc.icon}>
                <IconPhoto />
              </Avatar>
            </Paper>
            <Box w={"max-content"}>
              <Text size="sm" fw={500}>
                {record?.inAcc.shortName}
              </Text>
              <Text c="dimmed" size="sm">
                {record?.inAcc.name}
                <Text component="span" fw={700}>
                  , {record?.inAcc.number}
                </Text>
              </Text>
            </Box>
          </Flex>
        );
      return "-";
    },
  },
  {
    title: "Зарлага гаргах",
    render: (record) => {
      if (record.outAcc) {
        return (
          <Flex align="center" gap={10}>
            <Paper withBorder>
              <Avatar radius="xs" src={record?.inAcc.icon}>
                <IconPhoto />
              </Avatar>
            </Paper>
            <Box w={"max-content"}>
              <Text size="sm" fw={500}>
                {record?.outAcc.shortName}
              </Text>
              <Text c="dimmed" size="sm">
                {record?.outAcc.name}
                <Text component="span" fw={700}>
                  , {record?.outAcc.number}
                </Text>
              </Text>
            </Box>
          </Flex>
        );
      }
      return "-";
    },
  },
  {
    title: "Ангилал",
    render: (record) =>
      record?.classificationCategory ? (
        <Box w={"max-content"}>
          <Text w={"max-content"} size="sm">
            {record?.classificationCategory?.name}
          </Text>
          <Text size="sm" c="grape">
            #{record?.classificationCategory?.refCode}
          </Text>
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.classificationPriority?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Статус",
    render: (record) =>
      businessStatus?.find((f) => f.code === record.businessStatus)
        ? Tag(
            businessStatus?.find((f) => f.code === record.businessStatus)?.color ?? "gray",
            businessStatus?.find((f) => f.code === record.businessStatus)?.name ?? "-",
          )
        : "-",
  },
  {
    title: "Санхүү хариуцсан",
    render: (record) =>
      record?.coBusinessStaff ? (
        <Flex align="center" gap={10}>
          <UnstyledButton disabled={!record?.coBusinessStaff?.avatar} onClick={() => onClick("image", record?.coBusinessStaff?.avatar)}>
            <Avatar src={record?.coBusinessStaff?.avatar} />
          </UnstyledButton>
          <div>
            <Text size="sm" w="max-content">
              {record?.coBusinessStaff?.lastName ? record?.coBusinessStaff?.lastName[0] + "." : ""}
              {record?.coBusinessStaff?.firstName ?? "-"}
            </Text>
            <Text c="gray" size="sm" w="max-content">
              {record?.coBusinessStaff?.email ?? ""}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "И-мэйл хаяг",
    render: (record) => <Text size="sm">{record?.partnerEmail ?? "-"}</Text>,
  },
  {
    title: "Утасны дугаар",
    render: (record) => <Text size="sm">{record?.partnerPhone ?? "-"}</Text>,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Манай нийлүүлэгчид",
  },
];

const businessTypes = [
  {
    code: "SUPPLIER",
    name: "Нийлүүлэгч",
  },
  {
    code: "BUYER",
    name: "Худалдан авагч",
  },
];
